import { createRouter, createWebHistory } from 'vue-router';
import routes from "./routes";
import { usePostHog } from '../composables/usePosthog';

// configure router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
const { posthog } = usePostHog()

router.afterEach((to) => {
  posthog.capture('$pageview', {
    $current_url: to.fullPath,
    $referrer: document.referrer,
    utm_source: to.query.utm_source,
    utm_medium: to.query.utm_medium,
    utm_campaign: to.query.utm_campaign,
    utm_content: to.query.utm_content,
    utm_term: to.query.utm_term
  });
})

router.beforeEach((to, from) => {
  if (from.path !== to.path) {
    posthog.capture('$pageleave', {
      $current_url: from.fullPath,
      $next_url: to.fullPath
    })
  }
})

export default router;
