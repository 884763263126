// src/composables/usePostHog.ts
import posthog from 'posthog-js'

export function usePostHog() {
  posthog.init('phc_RLPGrzmq8VDaXSj2Ixai4vZey6GOQtpcRFGXSCeMvOH', {
    api_host: 'https://eu.i.posthog.com',
    autocapture:false

  })

  return {
    posthog
  }
}