import axios from "@/core/axios";

async function getQuestions(payload) {
  try {
    return axios({}).get(`/partner/user/${payload.partner_id}/questions?sort=occurrences&order=asc`, {
      params: {
        isAnswered: payload.isAnswered,
        perPage: payload.limit,
        page: payload.skip,
      },
    });
  } catch (e) {
    throw e;
  }
}

async function updateQuestion(payload) {
  try {
    return axios({}).post(`/partner/user/${payload.partner_id}/questions`, {
      answers: payload.answers,
    });
  } catch (e) {
    throw e;
  }
}

async function getApplications(payload) {
  try {
    return axios({}).get(`partner/applications?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
  } catch (e) {
    throw e;
  }
}

async function getApplicationsEmails(payload) {
  try {
    if (!payload.fromDate || !payload.toDate) {
      return axios({}).get(`partner/emails`);
    } else {
      return axios({}).get(`partner/emails?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
    }
  } catch (e) {
    throw e;
  }
}

export function getSubscription() {
  try {
    return axios({}).get("/partner/subscription");
  } catch (e) {
    throw e;
  }
}

export function changeInterviewStatus(payload) {
  try {
    return axios({}).put(`/partner/user/${payload.user}/match/${payload.matchId}/interview`, {
      interviewStatus: payload.interviewStatus,
    });
  } catch (e) {
    throw e;
  }
}

async function subscribePartnerBilling(payload) {
  try {
    return axios({}).post(`/partner/subscription`, {
      ...payload
    });
  } catch (e) {
    throw e;
  }
}

export async function getPartnerSettings() {
  try {
    return await axios({}).get("/partner/settings");
  } catch (e) {
    throw e;
  }
}


async function getMassApplyMatchesPartner(getMassApplyMatchesPartner) {
  try {
    return axios({}).get(`/partner/user/${getMassApplyMatchesPartner}/massApplyMatches`);
  } catch (e) {
    throw e;
  }
}

export async function putPartnerSettings(payload) {
  try {
    console.log(payload);
    return await axios({}).put("/partner/settings", payload);
  } catch (e) {
    throw e;
  }
}

export const partnerRepo = {
  getQuestions,
  updateQuestion,
  getApplications,
  getApplicationsEmails,
  getSubscription,
  changeInterviewStatus,
  subscribePartnerBilling,
  getPartnerSettings,
  putPartnerSettings,
  getMassApplyMatchesPartner
};
