<template>
    <div>
        <app-icon left name="candidatesIcon" v-bind="props" />
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>