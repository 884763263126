<template>
  <div v-if="this.loading">
    <app-loader />
  </div>
  <div v-else>
    <div v-if="subscriptionIsActive">
      <v-alert class="mx-5 mt-4" density="compact" type="success" variant="tonal" text :icon="false"
        v-if="!cancelAtPeriodEnd">
        <v-row class="align-center no-wrap">
          <v-col cols="12" sm="8">
            <div class="alert_messaging">
              <label>{{ this.$t("Settings.active_subscription_until") }}: {{ nextPayment }}.</label>
              <label>
                {{ this.$t("Settings.update_card_details") }}
                <router-link to="/billing" target="_blank">{{ this.$t("Generic.Common.here") }}</router-link>
              </label>
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="text-right">
            <v-btn variant="text" color="primary" class="cancel_btn" @click="showCancelModal()">
              {{ $t("plans.Common.cancel_subscription") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert class="mx-5 mt-4" density="compact" type="success" variant="tonal" text :icon="false" v-else>
        <v-row class="align-center no-wrap">
          <v-col cols="12" sm="8">
            <div class="alert_messaging">
              <label>{{ this.$t("Settings.cancel_subscription_at") }}: {{ nextPayment }}.</label>
              <label>
                {{ this.$t("Settings.update_card_details_reactivate_subscription") }}
                <router-link to="/billing" target="_blank">{{ this.$t("Generic.Common.here") }}</router-link>
              </label>
            </div>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <v-alert class="mx-5 mt-4" density="compact" type="warning" variant="tonal" text :icon="false" v-if="activeTrial">
      <span>{{ this.$t("plans.Common.subscription_expired_in_days", { remainingDays: remainingDays }) }}</span>
    </v-alert>

    <div class="pt-6" style="display: flex; justify-content: center">
      <PricingPlansBoxes @selected="handlePlanSelection" />
    </div>

    <ChangePlan ref="changePlanDialog" />
    <CancelSubscription ref="cancelSubscriptionDialog" @subscriptionCancelled="handleSubscriptionCancelled" />
  </div>
</template>

<script>
import PricingPlansBoxes from "@/components/subscription_plan/PricingPlansBoxes.vue";
import CancelSubscription from "./modals/CancelSubscription.vue";
import ChangePlan from "./modals/ChangePlan.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;
const plans = constant.plans;

export default {
  components: {
    PricingPlansBoxes,
    CancelSubscription,
    ChangePlan,
  },

  data() {
    return {
      newPlanSelected: -1,
      cancelAtPeriodEnd: false,
      nextPayment: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),
    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
      getStatus: plans.getters.GetStatus,
      error: plans.getters.GetError,
    }),

    status() {
      return this.getStatus;
    },

    user() {
      return this.getUser;
    },

    currentPricingPlan() {
      return this.user?.pricingPlan;
    },

    pricingPlansData() {
      if (this.$store.getters.isB2C) {
        return this.getPlans?.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    subscriptionIsActive() {
      if (!this.currentPricingPlan) {

        return false;
      }
      if (this.user.subscriptionStatus === "trialStart") {
        return false;
      }

      const activePlansData = this.pricingPlansData.find(({ index }) => index === this.currentPricingPlan);

      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },

    activeTrial() {
      return this.user?.subscriptionStatus === "trialStart" && this.currentPricingPlan;
    },

    remainingDays() {
      if (this.user.remainingDays <= 0) {
        return 0;
      }
      return this.user.remainingDays;
    },
  },

  async created() {
    this.checkForSuccessfulPayment();
    await this.$nextTick();

    if (this.subscriptionIsActive && this.currentPricingPlan > 1) {
      this.setupActiveSubInfo()
    }

    // Check for successful payment after Stripe redirects back
  },

  methods: {
    async setupActiveSubInfo() {
      try {
        this.loading = true;
        const result = await this.$store.dispatch(constant.user.actions.GetB2CSubscription);
        this.nextPayment = result.nextPayment;
        this.cancelAtPeriodEnd = result.cancelAtPeriodEnd;
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    handleSubscriptionCancelled() {
      this.cancelAtPeriodEnd = true;
    },

    showCancelModal() {
      this.$refs.cancelSubscriptionDialog.open();
    },

    showChangePlan(newPlan) {
      // @ts-ignore

      this.$refs.changePlanDialog.open(newPlan);
    },

    handlePlanSelection(newPlan) {
      if (this.subscriptionIsActive && this.currentPricingPlan > newPlan) return;
      if (this.pricingPlansData.find(({ index }) => index === newPlan).cost === 0) return;
      if (newPlan === this.currentPricingPlan) return;

      this.showChangePlan(newPlan);
    },

    async checkForSuccessfulPayment() {
      this.loading = true;

      const queryParam = this.$route.query.subscriptionStatus;

      if (queryParam === "success") {
        try {
          await new Promise((resolve) => setTimeout(resolve, 5000)); 
          await this.$store.dispatch(user.actions.Auth);
          await this.$store.dispatch(user.actions.RefreshUserData);

          this.$notify({
            message: this.$t("plans.Common.success_payment"),
            type: "success",
          });

          this.$emit("payment-success");
          this.$amplitude.logEvent("ALTERNATE_PAYMENT_SUCCESS");
          await this.setupActiveSubInfo();

          this.$router.replace({ query: {} });
        } catch (error) {
          this.$notify({
            message: error.message || this.$t("errors.unexpected"),
            type: "danger",
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    }

  },
};
</script>
<style>
.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pad-c {
  padding: 1em;
}

.cancelAlert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert_messaging {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cancel_btn {
  margin-left: auto;
}
</style>
