<template>
  <div class="pricing-plans-container">
    <div v-for="pricingPlan in pricingPlansData" :key="pricingPlan.index" class="pricing-plan-wrapper">
      <div class="pricing-box">
        <div class="name-area mb-3">
          <h3 class="name-text mb-3 text-h6">{{ pricingPlan.name }}</h3>
          <div style="text-align: center">
            <span class="price-text">{{ pricingPlan.description }}</span>
            <span class="period-text">{{ $t("plans.perMonth") }}</span>
          </div>
        </div>
        <div class="image-area">
          <img :src="pricingPlan.imageUrl" :class="{ blurFlower: activePricing == pricingPlan.index }"
            class="pricing-plan-image" width="100%" alt="Pricing Plan Illustration" />
        </div>
        <div>
          <v-btn v-if="activePricing >= pricingPlan.index || activePricing === 1" color="default" variant="outlined"
            class="text-button pricing-select-btn"
            :disabled="activePricing === pricingPlan.index || subscriptionIsActive || disableButtons"
            @click="$emit('selected', pricingPlan.index)">
            {{ activePricing === pricingPlan.index ? $t("plans.selected") : $t("plans.select") }}
          </v-btn>
          <div class="upgrade-button" v-else>
          
            <v-btn  @click="upgradeBtnClick(pricingPlan.index)" color="default" variant="outlined"
              class="text-button pricing-select-btn">
              {{ $t("plans.upgrade") }}
            </v-btn>
          </div>

        </div>
        <div style="text-align: left">
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <p class="description-text">
              {{ pricingPlan.maxLoops }}
            </p>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <p class="description-text">
              {{ pricingPlan.description2 }}
            </p>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.jobs }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.priority }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('extension')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.extension }}
            </label>
          </div>

          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('remote_anywhere')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.remote_anywhere }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('smtp')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.smtp }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('aiAnswers')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.aiAnswers }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('moreJobs')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.moreJobs }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('prioritySupport')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.prioritySupport }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('hiddenJobs')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.hiddenJobs }}
            </label>
          </div>

        </div>
        <v-spacer></v-spacer>

      </div>
    </div>
  </div>

  <v-dialog v-model="showUpgradeContactSupport" width="auto">
    <v-card>
      <v-card-text class="pa-8">
        Contact <a href="mailto:support@loopcv.com" class="email-link">support@loopcv.com</a> for a personalized experience
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="showUpgradeContactSupport = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import tick from "@/assets/images/tick-price.svg";
import xdBike from "@/assets/new-design-icons/Group 23572.svg";
import xdMotorbike from "@/assets/new-design-icons/Group 23573.svg";
import xdCar from "@/assets/new-design-icons/Group 23574.svg";
import rocket from "@/assets/new-design-icons/rocket.svg";

import { constant } from "@/store/constant";
import { toCurrency } from "@/helpers/utils/stringUtils.js";
import EventBus from "@/plugins/eventsBus";

const plans = constant.plans;
const user = constant.user;

export default {
  data() {
    return {
      tick,
      disableButtons: false,
      localActivePricing: null,
      showUpgradeContactSupport:false
    };
  },
  created() {
    EventBus.on('payment-success', this.onPaymentSuccess);
  },
  beforeDestroy() {
    EventBus.off('payment-success', this.onPaymentSuccess);
  },
  computed: {
    ...mapGetters("plans", { getPlans: plans.getters.GetPlans }),
    ...mapGetters("user", { getUser: user.getters.getUser }),

    activePricing() {
      return this.localActivePricing !== null ? this.localActivePricing : this.getUser?.pricingPlan;
    },

    pricingPlansData() {
      if (process.env.VUE_APP_MODE === "b2c") {
        const frontendObject = [
          {
            index: 1,
            name: this.$t("plans.basic.name"),
            maxLoops: this.$t("plans.basic.maxLoops"),
            description2: this.$t("plans.basic.description2"),
            jobs: this.$t("plans.basic.job_board"),
            priority: this.$t("plans.basic.priority"),
            imageUrl: xdBike,
            icon: "looks_one",
            extension: this.$t("plans.extension")
          },
          {
            index: 2,
            name: this.$t("plans.standard.name"),
            maxLoops: this.$t("plans.standard.maxLoops"),
            description2: this.$t("plans.standard.description2"),
            jobs: this.$t("plans.standard.job_board"),
            priority: this.$t("plans.standard.priority"),
            smtp: this.$t("plans.smtp"),
            remote_anywhere: this.$t("plans.remote_anywhere"),
            imageUrl: xdMotorbike,
            icon: "looks_two",
            extension: this.$t("plans.extension")
          },
          {
            index: 3,
            name: this.$t("plans.premium.name"),
            maxLoops: this.$t("plans.premium.maxLoops"),
            description2: this.$t("plans.premium.description2"),
            jobs: this.$t("plans.premium.job_board"),
            priority: this.$t("plans.premium.priority"),
            smtp: this.$t("plans.smtp"),
            remote_anywhere: this.$t("plans.remote_anywhere"),
            imageUrl: xdCar,
            icon: "looks_3",
            extension: "Linkedin apply extension",
            aiAnswers: this.$t("plans.premium.aiAnswers"),
            moreJobs: this.$t("plans.premium.moreJobs"),
            prioritySupport: this.$t("plans.premium.prioritySupport"),
            hiddenJobs: this.$t("plans.premium.hiddenJobs"),
          },
          {
            index: 4,
            name: this.$t("plans.doneForYou.name"),
            maxLoops: this.$t("plans.doneForYou.maxLoops"),
            description2: this.$t("plans.doneForYou.description2"),
            jobs: this.$t("plans.doneForYou.job_board"),
            priority: this.$t("plans.doneForYou.priority"),
            imageUrl: rocket,
            icon: "looks_3",
            aiAnswers: this.$t("plans.doneForYou.aiAnswers"),
            moreJobs: this.$t("plans.doneForYou.moreJobs"),
          },
        ];


        const model = frontendObject.map((frontendItem, index) => {
          const currency = frontendItem.index === 4 ? "USD" : this.getPlans.currency;
          const cost = this.getPlans.plans[index].cost;
          const fullItem = {
            ...frontendItem,
            ...this.getPlans.plans[index],
            description: `${toCurrency(cost, currency)}`,
            currency: currency,
          };
          return fullItem;
        });
        return model;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    subscriptionIsActive() {
      // TODO should be 1, 2, 3
      if (!this.activePricing) {
        return false;
      }
      const activePlansData = this.pricingPlansData.find(({ index }) => index === this.activePricing);
      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },
  },
  methods: {
    onPaymentSuccess(plan) {
      this.disableButtons = true;
      this.localActivePricing = plan;
    },
    upgradeBtnClick(pricingPlan) {
      if (pricingPlan === 4) {
        this.showUpgradeContactSupport = true;
        return;
      }
      this.$emit("selected", pricingPlan);

    }
  }
};
</script>

<style>
.pricing-plans-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  padding: 16px 0;
}

.pricing-plan-wrapper {
  flex: 0 0 auto;
  width: calc(25% - 12px);
  min-width: 250px;
  max-width: 300px;
}

.pricing-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 15px 10px;
  max-width: 400px;
  height: 100%;
}

.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}

.name-area {
  min-height: 5em;
}

.image-area {
  margin: 0 auto;
  display: block;
}

.pricing-plan-image {
  margin: 0 auto;
  display: block;
  min-height: 200px;
  max-width: 160px;
}

.price-text {
  font: normal normal 600 29px/29px Manrope;
  text-align: center;
  padding-right: 5px;
}

.period-text {
  font: normal normal medium 14px/19px Manrope;
  text-align: center;
  letter-spacing: 0.14px;
  color: #989696;
}

.name-text {
  font: normal normal 600 16px/20px Manrope;
  text-align: center;
  letter-spacing: 0.16px;
  color: #35383c;
  padding-top: 5px;
}

.description_text_block {
  margin-top: 4px;
}

.description-text {
  font: normal normal medium 14px/18px Manrope;
  text-align: left;
  letter-spacing: 0.14px;
  color: #35383c;
  min-height: 50px;
  padding-left: 8px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.dot {
  width: 9px;
  height: 9px;
  background: #2f7bc3 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 9999px;
}

.pricing-select-btn {
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
}

.pricing-select-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent);
  transition: all 0.6s ease;
}

.pricing-select-btn:hover::before {
  left: 100%;
}

.pricing-select-btn:hover {
  transform: scale(1.05);
}

.pricing-select-btn:active {
  transform: scale(0.95);
}

.contact-support {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
}

.email-link {
  color: #007bff;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .pricing-plans-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>
