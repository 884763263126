import { generateActionTypes } from "@/helpers/utils/arrayUtils";

const Module = "plans";

export const status = {
  SuccessFetchPrices: "SuccessFetchPrices",
  FailureFetchPrices: "FailureFetchPrices",

  SuccessPromoCode: "SuccessPromoCode",
  FailurePromoCode: "FailurePromoCode",

  SuccessCreateSubscription: "SuccessCreateSubscription",
  FailureCreteSubscription: "FailureCreteSubscription",

  SuccessCancelSubscription: "SuccessCancelSubscription",
  FailureCancelSubscription: "FailureCancelSubscription",
};

const actionsList = [`fetchPrices`, `cancelSubscription`, `createSubscription`, `addPromoCode`, `removePromoCode`];

export const actions = generateActionTypes(Module, actionsList);

export const mutation = {
  setPlans: "setPlans",
  setState: "setState",
  setLoading: "setLoading",
  setError: "setError",
  setPromoCode: "setPromoCode",
  setPromoCodeError: "setPromoCodeError",
};

export const getters = {
  GetPlans: `getPlans`,
  GetStatus: `getStatus`,
  GetError: "getError",
  GetPromoCode: "getPromoCode",
  GetPromoCodeError: "getPromoCodeError",
};
