import appSDK from "@/client/AppSDK";

const state = {
  loopPostData: {},
  action: null, // should be either 'create' or 'edit' or 'duplicate'
  jobTypes: [],
  jobLevels: [],
};

const getters = {
  getLoopData(state) {
    return state.loopPostData || {};
  },
  isEditMode(state) {
    return state.action === "edit";
  },
  isEditOrDuplicate(state) {
    return state.action === "edit" || state.action === "duplicate";
  },
  jobTypes(state) {
    return state.jobTypes;
  },
  jobLevels(state) {
    return state.jobLevels;
  },
};

const mutations = {
  setLoopData(state, payload) {
    console.log({state, payload})
    state.loopPostData = {
      ...state.loopPostData,
      ...payload,
    };
  },
  clearLoopData(state) {
    state.loopPostData = {};
  },
  setLoopAction(state, payload) {
    state.action = payload;
  },
  setJobLevels(state, payload) {
    state.jobLevels = payload;
  },
  setJobTypes(state, payload) {
    state.jobTypes = payload;
  },
};

const actions = {
  async fetchConstants({ commit }) {
    commit("setJobLevels", await appSDK["jobs.getLevels"]());
    commit("setJobTypes", await appSDK["jobs.getTypes"]());
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
