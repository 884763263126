import {
  mdiDelete,
  mdiEmail,
  mdiEmailSend,
  mdiMagnify,
  mdiLinkVariant,
  mdiPlusBoxMultiple,
  mdiAccountCircle,
  mdiViewDashboard,
  mdiFileRefresh,
  mdiLogoutVariant,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiLock,
  mdiLabel,
  mdiEmailCheckOutline,
  mdiApplicationImport,
  mdiFormatBold,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatItalic,
  mdiFormatStrikethrough,
  mdiFormatParagraph,
  mdiInbox,
  mdiCommentQuestion,
  mdiProgressQuestion,
  mdiCommentQuestionOutline,
  mdiHelpCircleOutline,
  mdiCloseBoxOutline,
  mdiGoogle,
  mdiTranslate,
  mdiPencil,
  mdiContentCopy,
  mdiDotsVertical,
  mdiCircleSmall,
  mdiAlert,
  mdiWeb,
  mdiAccountWrenchOutline,
  mdiReceipt,
  mdiEyeArrowRightOutline,
  mdiWindowClose,
  mdiPuzzleOutline,
  mdiAccountCogOutline,
  mdiCogOutline
} from "@mdi/js";

export default {
  email: mdiEmail,
  delete: mdiDelete,
  emailSend: mdiEmailSend,
  magnify: mdiMagnify,
  link: mdiLinkVariant,
  plusBoxMultiple: mdiPlusBoxMultiple,
  accountCircle: mdiAccountCircle,
  label: mdiLabel,
  dashboard: mdiViewDashboard,
  fileRefresh: mdiFileRefresh,
  logout: mdiLogoutVariant,
  close: mdiClose,
  eye: mdiEye,
  eyeOff: mdiEyeOff,
  lock: mdiLock,
  emailCheckOutline: mdiEmailCheckOutline,
  applicationImport: mdiApplicationImport,
  bold: mdiFormatBold,
  header1: mdiFormatHeader1,
  header2: mdiFormatHeader2,
  header3: mdiFormatHeader3,
  italic: mdiFormatItalic,
  strike: mdiFormatStrikethrough,
  paragraph: mdiFormatParagraph,
  inbox: mdiInbox,
  commentQuestion: mdiCommentQuestion,
  commentQuestionOutline: mdiCommentQuestionOutline,
  progressQuestion: mdiProgressQuestion,
  helpCircleOutline: mdiHelpCircleOutline,
  closeBoxOutline: mdiCloseBoxOutline,
  google: mdiGoogle,
  translate: mdiTranslate,
  pencil: mdiPencil,
  contentCopy: mdiContentCopy,
  dotsVertical: mdiDotsVertical,
  circleSmall: mdiCircleSmall,
  alert: mdiAlert,
  web: mdiWeb,
  acountWrenchOutline: mdiAccountWrenchOutline,
  receipt: mdiReceipt,

  xdProfileAppBar:
    "M5.58,6.79c0,3.55,2.87,6.42,6.42,6.42s6.42-2.87,6.42-6.42S15.55,.37,12,.37c-3.55,0-6.42,2.87-6.42,6.42Zm11.16,0c0,2.62-2.12,4.75-4.75,4.75s-4.75-2.12-4.75-4.75,2.12-4.75,4.75-4.75h0c2.62,0,4.74,2.12,4.74,4.74h0Zm-2.59,7.96c-1.41,.45-2.92,.45-4.33,0-2.75-.97-5.82-.14-7.71,2.09C.75,18.52,0,20.62,0,22.79c0,.46,.38,.84,.84,.84,.46,0,.84-.37,.84-.84,0-1.78,.61-3.51,1.74-4.89,1.45-1.71,3.81-2.34,5.92-1.59,1.74,.56,3.62,.56,5.36,0,2.11-.75,4.47-.12,5.91,1.59,1.12,1.38,1.73,3.11,1.72,4.89,0,.46,.38,.84,.84,.84,.46,0,.84-.37,.84-.84,0-2.17-.75-4.27-2.12-5.95-1.89-2.23-4.97-3.06-7.73-2.09Z",
  xdLoop:
    "M11.72 3.14c-4.81.15-8.65 4.05-8.72 8.86h-3l4.5 4.5 4.5-4.5h-3c0-3.3 2.68-5.96 5.98-5.96 1.57 0 3.08.63 4.2 1.73l2.16-2.06c-1.26-1.31-2.9-2.18-4.69-2.48-.63-.1-1.28-.13-1.92-.1zm7.78 4.36-4.5 4.5h3c0 3.3-2.68 5.96-5.98 5.96-1.57 0-3.08-.63-4.2-1.73l-2.16 2.06c5.38 5.56 15.33 1.57 15.33-6.28h3l-4.5-4.5z",
  xdConnections:
    "M21.32,15.11c-.42-.14-.86-.21-1.3-.21-.97,0-1.92,.37-2.64,1.03l-4.29-2.58v-4.54c2.12-.54,3.39-2.71,2.84-4.83-.54-2.11-2.71-3.39-4.83-2.84-2.12,.55-3.39,2.71-2.84,4.83,.36,1.39,1.45,2.48,2.84,2.84v4.54l-4.29,2.58c-1.66-1.52-4.23-1.4-5.74,.25-1.52,1.66-1.41,4.23,.25,5.74,1.65,1.52,4.22,1.4,5.74-.25,1.01-1.11,1.33-2.68,.84-4.09l4.19-2.52,4.2,2.52c-.71,2.07,.39,4.33,2.46,5.04,2.07,.7,4.33-.4,5.04-2.47,.7-2.07-.4-4.33-2.47-5.04ZM4.17,20.85c-1.1,0-1.98-.89-1.98-1.99s.88-1.98,1.98-1.98,1.98,.89,1.98,1.98-.88,1.98-1.98,1.99ZM12.09,6.97c-1.09,0-1.98-.89-1.98-1.98s.89-1.98,1.98-1.98c1.1-.01,1.98,.88,1.99,1.98,0,1.09-.89,1.98-1.98,1.98h0Zm7.94,13.88h-.01c-1.09,0-1.98-.89-1.98-1.99s.89-1.98,1.98-1.98,1.98,.89,1.98,1.98c.01,1.09-.88,1.98-1.97,1.99Z",
  xdClosedEmail:
    "M20.04,10.92V3.2c0-.4-.32-.73-.73-.73H.73c-.4,0-.73,.33-.73,.73V15.45c0,.41,.33,.73,.73,.73H13.18c.03,2.95,2.45,5.35,5.41,5.35s5.41-2.43,5.41-5.41c0-2.48-1.68-4.57-3.96-5.21Zm-13.26-1.93L1.46,13.79V4.51l5.32,4.48ZM3.03,3.92h14.11l-7.04,5.96L3.03,3.92Zm15.55,.68v6.11c-.94,0-1.83,.24-2.6,.67l-2.61-2.36,5.22-4.42ZM2.59,14.72l5.3-4.79,1.73,1.46c.14,.11,.3,.17,.47,.17s.33-.06,.47-.17l1.68-1.42h0l2.53,2.3c-.67,.67-1.17,1.51-1.42,2.45H2.59Zm16,5.45c-2.21,0-4.02-1.79-4.05-3.99v-.06c0-.5,.09-.96,.25-1.4,.21-.58,.56-1.11,1-1.52,.37-.35,.8-.63,1.27-.83,.47-.19,.98-.3,1.52-.3h0c.51,0,1,.1,1.45,.27,1.52,.58,2.6,2.06,2.6,3.78,0,2.23-1.82,4.05-4.05,4.05Zm1.51-5.85l.96,.96-3.1,3.11-1.83-1.83,.39-.39,.57-.57,.86,.86,.29-.29,.4-.4,1.03-1.04,.42-.42Z",

  xdApplication:
    "M18.49,13.07V2.24c0-1.24-1.01-2.24-2.24-2.24H3.21C1.97,0,.96,1.01,.96,2.24V20.2c0,1.25,1.01,2.24,2.24,2.24H13.7c.99,.96,2.34,1.55,3.83,1.55,3.04,0,5.51-2.47,5.51-5.51,0-2.71-1.97-4.96-4.55-5.42Zm-5.97,7.69H3.21c-.31,0-.56-.25-.56-.56V2.24c0-.31,.26-.56,.56-.56h13.03c.31,0,.56,.26,.56,.56V13.03c-.8,.1-1.55,.39-2.21,.8H4.67v1.19H13.26c-.77,.94-1.24,2.16-1.24,3.47,0,.81,.18,1.58,.49,2.27Zm5.01,1.85c-.41,0-.8-.06-1.17-.18-.57-.16-1.1-.45-1.53-.83-.29-.25-.53-.52-.74-.84-.43-.65-.68-1.43-.68-2.27,0-2.03,1.47-3.72,3.4-4.05,.24-.05,.47-.07,.72-.07,.04,0,.08,0,.12,0,.29,0,.57,.04,.84,.11,1.81,.43,3.16,2.06,3.16,4,0,2.27-1.85,4.12-4.12,4.12ZM11.8,4.49v1.19H4.67v-1.19h7.13Zm3.19,2.33v1.19H4.67v-1.19H15Zm0,2.33v1.19H4.67v-1.19H15Zm0,2.34v1.19H4.67v-1.19H15Zm5.04,6.14l-1.55,1.55-.84,.85-.76,.76-.23-.23-1.63-1.63,.98-.98,.88,.88,.76-.76,.84-.84,.57-.57,.98,.97Z",

  xdCV: "M18.49,13.07V2.24c0-1.24-1.01-2.24-2.24-2.24H3.21C1.97,0,.96,1.01,.96,2.24V20.2c0,1.25,1.01,2.24,2.24,2.24H13.7c.99,.96,2.34,1.55,3.83,1.55,3.04,0,5.51-2.47,5.51-5.51,0-2.71-1.97-4.96-4.55-5.42Zm-6.44,4.9c-.02,.17-.03,.34-.03,.51,0,.81,.18,1.58,.49,2.27H3.21c-.31,0-.56-.25-.56-.56V2.24c0-.31,.26-.56,.56-.56h13.03c.31,0,.56,.26,.56,.56V13.03c-1.16,.15-2.2,.66-3.02,1.42H4.67v1.18H12.82c-.23,.36-.41,.75-.53,1.16H4.67v1.19h7.38Zm5.48,4.64c-.41,0-.8-.06-1.17-.18-.57-.16-1.1-.45-1.53-.83-.29-.25-.53-.52-.74-.84-.43-.65-.68-1.43-.68-2.27,0-.18,0-.35,.04-.51,.05-.42,.16-.82,.34-1.19,.19-.44,.45-.82,.78-1.16,.13-.14,.28-.28,.44-.4,.51-.41,1.14-.68,1.81-.8,.24-.05,.47-.07,.72-.07,.04,0,.08,0,.12,0,.29,0,.57,.04,.84,.11,1.81,.43,3.16,2.06,3.16,4,0,2.27-1.85,4.12-4.12,4.12Zm2.51-4.97l-1.55,1.55-.84,.85-.76,.76-.23-.23-1.63-1.63,.98-.98,.88,.88,.76-.76,.84-.84,.57-.57,.98,.97Zm-5.04-5.53v1.19H4.67v-1.19H15Zm-2.33-4.28c-.45-.51-1.09-.79-1.74-.79,.53-.38,.88-1,.88-1.7,0-1.15-.93-2.08-2.08-2.08s-2.08,.93-2.08,2.08c0,.69,.34,1.32,.87,1.7-.65,0-1.3,.29-1.73,.8-.42,.51-.65,1.16-.65,1.82,0,.32,.26,.57,.57,.57s.57-.26,.57-.57c0-.4,.14-.78,.39-1.09,.3-.36,.78-.48,1.23-.33,.54,.17,1.12,.18,1.67,0,.44-.16,.92-.03,1.22,.32,.25,.32,.39,.7,.39,1.1,0,.32,.26,.57,.57,.57s.57-.26,.57-.57c0-.66-.23-1.31-.65-1.83Zm-2.94-1.55c-.52,0-.94-.42-.94-.94s.42-.93,.94-.93,.94,.42,.94,.93-.43,.94-.94,.94Z",

  xdOpenedEmail:
    "M22.12,8.88l-2.68-2.15-.06-4.08c-.01-.46-.38-.83-.84-.83h-5.21l-1.87-1.5c-.32-.25-.77-.24-1.07,.02l-1.76,1.48H3.76c-.23,0-.44,.09-.6,.25-.16,.17-.25,.38-.25,.61l.07,3.87L.19,8.89c-.19,.16-.3,.39-.3,.64v13.76c0,.47,.38,.84,.84,.84H21.59c.47,0,.84-.37,.84-.84V9.53c0-.25-.11-.5-.32-.65Zm-7.16,7.2l1.22-1.04,.29-.24,.24-.2,.29-.25,2.22-1.86s.07-.07,.1-.11h.01v-.02l1.42-1.19v10.17l-5.79-5.26ZM1.58,11.06l5.9,4.98L1.58,21.37V11.06ZM4.63,3.5h13.08l.02,1.85,.03,2.2,.06,3.9-6.6,5.52-6.45-5.52-.07-4.13-.05-2.17-.02-1.63Zm15.53,5.96l-.66,.56-.02-1.1,.69,.54Zm-17.14-.75l.02,1.39-.83-.7,.81-.69Zm-.12,13.74l5.89-5.32,1.91,1.62c.16,.14,.35,.2,.54,.2,.2,0,.39-.07,.55-.2l1.87-1.58,5.8,5.27H2.9ZM12.76,6.68H6.21v-1.13h6.56v1.13Zm3.37,2.35H6.21v-1.13h9.92v1.13Zm0,2.34H6.21v-1.13h9.92v1.13Z",

  xdSearch:
    "M23.41,8.3c-.01-.12-.01-.24-.03-.35h0c-.06-.41-.14-.82-.25-1.22-.1-.36-.21-.71-.36-1.04-.1-.28-.23-.54-.36-.79-.44-.86-1.04-1.63-1.74-2.32-3.67-3.54-9.54-3.44-13.07,.23-3.29,3.42-3.44,8.73-.48,12.3L.52,21.94l2.13,2.06,6.69-6.92c.48,.3,.99,.56,1.5,.76,.53,.21,1.08,.36,1.63,.47h0c.1,.02,.2,.02,.3,.04h0c.27,.04,.53,.05,.8,.07,.22,.02,.44,.06,.66,.06,.92,0,1.83-.13,2.7-.39,1.46-.44,2.83-1.25,3.96-2.42,.41-.43,.79-.9,1.1-1.4,.16-.25,.31-.49,.44-.76,.28-.54,.51-1.1,.67-1.69,.08-.28,.15-.56,.2-.84,.07-.26,.1-.54,.12-.82,.03-.28,.05-.56,.05-.86v-.21c0-.26-.04-.52-.06-.78Zm-4.66,5.3c-2.4,2.5-6.38,2.57-8.88,.15-2.48-2.4-2.57-6.38-.15-8.88,1.23-1.27,2.86-1.91,4.52-1.91,1.56,0,3.14,.58,4.36,1.76,1.2,1.17,1.89,2.73,1.91,4.41,.03,1.68-.59,3.27-1.76,4.47Z",
  xdTiles:
    "M24,.63V10.74H10.1V.63h13.9ZM13.9,13.26v10.1H0V13.26H13.9Zm10.1,0v10.1h-7.58V13.26h7.58ZM7.58,.63V10.74H0V.63H7.58Z",
    
    checkboxCircled:"M18.465 1.70492L17.055 0.294922L10.715 6.63492L12.125 8.04492L18.465 1.70492ZM22.705 0.294922L12.125 10.8749L7.945 6.70492L6.535 8.11492L12.125 13.7049L24.125 1.70492L22.705 0.294922ZM0.875 8.11492L6.465 13.7049L7.875 12.2949L2.295 6.70492L0.875 8.11492Z",
  xdPendingApplications:"M12 6.49L19.53 19.5H4.47L12 6.49ZM12 2.5L1 21.5H23L12 2.5ZM13 16.5H11V18.5H13V16.5ZM13 10.5H11V14.5H13V10.5Z",
  eyeVisibility:mdiEyeArrowRightOutline,
  windowsClose:mdiWindowClose,  
  extension:mdiPuzzleOutline,
  candidatesIcon:mdiAccountCogOutline,
  cog:mdiCogOutline

};

